import React, { useState } from "react";
import "./App.css";
import myVideo from "./bg-video.mp4"; // <-- import your video here

function App() {
  const [teamIsOpen, setTeamIsOpen] = useState(false);
  const [projectsIsOpen, setProjectsIsOpen] = useState(false);
  const [clientsIsOpen, setClientsIsOpen] = useState(false);

  return (
    <>
      <div className="w-full sm:w-3/4 lg:w-2/5 max-w-screen-lg mx-auto p-10 lg:pt-20 sm:pt-20">
        <h1 className="text-4xl mb-5">🐇</h1>
        <h1 className="text-3xl font-bold mb-2">Generative Magic</h1>
        <p className="mb-4 text-md">
          We are a collective of AI developers, engineers, students, and
          futurist thinkers working on expanding human potential. The future
          imagined by science fiction is arriving faster than we ever dreamed.
        </p>
        <p className="mb-4 text-md">
          Just as the personal computer, Internet, and smartphone ushered in new
          eras of creative possibility, we stand at the dawn of the AI age.
          Tools like large language models and diffusion are just the beginning.
        </p>
        <p className="text-md">
          Our backgrounds include research and applied AI engineering at
          startups and Stanford. We've built prototypes and ran workshops for
          Fortune 100 companies, startups, and more.
        </p>
        <br />

        <div className="mb-4">
          <a
            className="font-bold pr-1"
            target="_blank"
            href="https://generativemagic.substack.com/"
          >
            Newsletter
          </a>
          //
          <a
            className="font-bold px-1"
            target="_blank"
            href="https://twitter.com/generativemagic"
          >
            Twitter
          </a>
          //
          <a className="font-bold pl-1" href="mailto:team@generativemagic.com">
            Work with us
          </a>
        </div>

        {/* <h3 className="text-lg font-bold mb-2">PROJECTS</h3>
        <ul className="list-square text-sm">
          <li>Promptlang</li>
          <li>HealthGPT</li>
          <li>Llama Engineer</li>
        </ul>
        <br />
        <h3 className="text-lg font-bold mb-2">CLIENTS</h3> */}
      </div>
      <div className="video-wrapper">
        <video className="video-background" playsinline autoPlay loop muted>
          <source src={myVideo} type="video/mp4" />
        </video>
        <div className="overlay"></div>
      </div>
    </>
  );
}

export default App;
